<template>
  <v-text-field
    v-if="!firstLoad"
    v-bind:value="input.value"
    @change="
      input.value = $event;
      $emit('passChange', input);
    "
    :label="input.name"
    :append-icon="input.icon ? input.icon : ''"
    :rules="rulesObj(input.rules)"
    :required="input.required ? input.required : false"
    outlined
    dense
    color="#3d2cdd"
  >
  </v-text-field>
</template>
<script>
export default {
  name: "FormTextField",
  props: ["input"],
  data() {
    return {};
  },
  methods: {
    rulesObj(input) {
      // If the rules object exists then return
      // Else don't return anything
      if (typeof input === "undefined") return;
      return Object.values(input);
    },
  },
};
</script>